import { graphql } from 'gatsby';
import React from 'react';
import { Contest } from '../components/Contest';
import { PublicLayout } from '../components/Shared/PublicLayout';

export default function ContestPage({ data }: any) {
  const contests = data.allMarkdownRemark.edges;

  return (
    <PublicLayout>
      <Contest data={contests} />
    </PublicLayout>
  );
}

export const pageQuery = graphql`
  query GetContests {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/contests/" } }) {
      edges {
        node {
          id
          frontmatter {
            title
            description
          }
          html
        }
      }
    }
  }
`;
