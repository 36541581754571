import React from 'react';

interface Props {
  value: string;
}

export function PageTitle({ value }: Props) {
  return <section className="text-4xl font-bold text-black">{value}</section>;
}

export function SectionTitle({ value }: Props) {
  return (
    <section className="pt-5 mb-5">
      <section className="text-2xl font-bold text-black">{value}</section>
    </section>
  );
}
