import { LinkIcon } from '@heroicons/react/outline';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { ConstestEnum } from '../../enums/ContestEnum';
import { PageTitle } from '../Shared/PageTitle';
import SeoHeader from '../Shared/SeoHeader';
import { Wrapper } from '../Shared/Wrapper';
import { CardWrapper } from '../Shared/Wrapper/CardWrapper';

type Props = {
  data: any;
};

export function Contest({ data }: Props) {
  return (
    <Wrapper>
      <SeoHeader title="Contests" />

      <section className="flex flex-col w-full py-5">
        <PageTitle value="Contests" />

        <section className="mt-5">
          <section className="flex flex-row flex-wrap">
            {data.map((item: any, index: number) => {
              return (
                <section
                  key={index}
                  className="w-full sm:w-6/12 md:w-6/12 lg:w-4/12 flex flex-col px-2 py-2"
                >
                  <CardWrapper animate>
                    <section className="p-2 mb-5">
                      <section
                        className="w-8/12 mx-auto text-center"
                        style={{ height: 180 }}
                      >
                        {item.node.frontmatter.title ===
                        ConstestEnum.PhotoContest ? (
                          <StaticImage
                            className="cursor-pointer hover:opacity"
                            quality={95}
                            formats={['webp', 'auto', 'avif']}
                            width={200}
                            height={180}
                            src={`../../assets/images/c-photo.png`}
                            alt="NSTW Logo"
                            placeholder="tracedSVG"
                          />
                        ) : null}

                        {item.node.frontmatter.title ===
                        ConstestEnum.PosterSlogan ? (
                          <StaticImage
                            className="cursor-pointer hover:opacity"
                            quality={95}
                            formats={['webp', 'auto', 'avif']}
                            width={200}
                            height={180}
                            src={`../../assets/images/c-poster-making.png`}
                            alt="NSTW Logo"
                            placeholder="tracedSVG"
                          />
                        ) : null}

                        {item.node.frontmatter.title ===
                        ConstestEnum.ShoutItOut ? (
                          <StaticImage
                            className="cursor-pointer hover:opacity"
                            quality={95}
                            formats={['webp', 'auto', 'avif']}
                            width={200}
                            height={180}
                            src={`../../assets/images/c-shout-it-out.png`}
                            alt="NSTW Logo"
                            placeholder="tracedSVG"
                          />
                        ) : null}

                        {item.node.frontmatter.title ===
                        ConstestEnum.TechTok ? (
                          <StaticImage
                            className="cursor-pointer hover:opacity"
                            quality={95}
                            formats={['webp', 'auto', 'avif']}
                            width={200}
                            height={180}
                            src={`../../assets/images/c-tech-tok.png`}
                            alt="NSTW Logo"
                            placeholder="tracedSVG"
                          />
                        ) : null}

                        {item.node.frontmatter.title ===
                        ConstestEnum.TestimonialVideo ? (
                          <StaticImage
                            className="cursor-pointer hover:opacity"
                            quality={95}
                            formats={['webp', 'auto', 'avif']}
                            width={200}
                            height={180}
                            src={`../../assets/images/c-testimonial-video.png`}
                            alt="NSTW Logo"
                            placeholder="tracedSVG"
                          />
                        ) : null}
                        {item.node.frontmatter.title ===
                        ConstestEnum.SciTalk ? (
                          <StaticImage
                            className="cursor-pointer hover:opacity"
                            quality={95}
                            formats={['webp', 'auto', 'avif']}
                            width={200}
                            height={180}
                            src={`../../assets/images/c-scitalk.png`}
                            alt="NSTW Logo"
                            placeholder="tracedSVG"
                          />
                        ) : null}
                      </section>
                    </section>

                    <section className="flex flex-col justify-between h-full text-center">
                      <h1 className="text-4xl uppercase mb-5 text-primary-2 font-hero-rounded">
                        {item.node.frontmatter.title}
                      </h1>
                      <section
                        className="w-full md:w-8/12 mx-auto"
                        dangerouslySetInnerHTML={{ __html: item.node.html }}
                      />

                      <a
                        className="
                            hover:opacity-80 flex flex-row space-x-2 justify-center
                            transform hover:-translate-y-0.5 transition-all duration-150
                          bg-primary-1 text-white w-full md:w-6/12 mx-auto p-3 uppercase text-sm text-center rounded-lg"
                        href={item.node.frontmatter.description}
                        target="_blank"
                      >
                        <span>Gusto kong Sumali →</span>
                      </a>
                    </section>
                  </CardWrapper>
                </section>
              );
            })}
          </section>
        </section>
      </section>
    </Wrapper>
  );
}
